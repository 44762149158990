import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import setAuthToken from "../../component/config/setAuthToken";
import { BASE_URL } from "../../component/config/web.config";

// Login Api
export const actionLogin = createAsyncThunk("actionLogin",
  async (data, { dispatch, rejectWithValue }) => {
    const { values, navigate, checked } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/log-in`, values);
      const { status, message: customMsg, data: responseData } = res.data;

      if (parseInt(status) === 200) {
        message.success(customMsg, 5);
        setAuthToken(responseData.accessToken);
        dispatch(authMe());
        navigate && navigate(`/`);
        return responseData;

      } else {
        message.error(customMsg, 5);
      }
    } catch (error) {
      message.error(error?.message, 5);
      throw rejectWithValue(error?.message);
    }
  }
);

// Auth me
export const authMe = createAsyncThunk("authMe", async () => {
  try {
    const res = await axios.get(`${BASE_URL}/auth/me`);
    const { status, message: customMsg, data } = res.data;
    if (parseInt(status) === 200) {
      return data;
    } else if (parseInt(status) === 401) {
      localStorage.removeItem("pinalBackendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
      message.error(customMsg, 5);
    } else {
      message.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("pinalBackendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
      message.error(error?.message, 5);
    }
  }
});


// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async (navigate) => {
    // Remove token from localStorage
    localStorage.removeItem("pinalBackendJwtToken");
    setAuthToken(false);
    navigate("/login");
  }
);
