
// const mode = "DEV";
const mode = "PROD";

export const BASE_URL =
  mode === "PROD"
    ? "https://api.ilma.org.in"
    : "https://api.ilma.org.in";

export const BASE_URL_UPLOAD =
  mode === "PROD"
    ? "https://api.ilma.org.in/upload"
    : "https://api.ilma.org.in/upload";