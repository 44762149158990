import logo from "./logo.svg";
import "./assets/scss/index.scss";
import Login from "./component/auth/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import { useEffect } from "react";
import { configureStore } from "@reduxjs/toolkit";
import { ConfigProvider } from "antd";
import InnerPage from "./component/InnerPage";

const PrivateRoute = ({ element: Component }) => {
  const token = localStorage.getItem("pinalBackendJwtToken");

  useEffect(() => {
    if (!token) {
      // Redirect to the login page if there is no token
      window.location.href = "/login";
    }
  }, [token]);

  return <>{token && <Component />}</>;
};
function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button:{

          }
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/inner-page" element={<InnerPage />} />
          <Route path="/" element={<PrivateRoute element={Home} />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
