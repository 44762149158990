import { createSlice } from "@reduxjs/toolkit";
import {
  actionCreatePresentation,
  actionDeletePresentation,
  actionGetAllPresentation,
  actionGetSinglePresentation,
} from "../services/homeService";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    presentations: [],
    presentationLoader: false,
    CreatePresentationLoader: false,
    deletePresentationLoader: false,
    singlePresentation: false,
    singlePresentationLoader: false
  },
  reducers: {
    deleteSuccess: (state) => {
      state.deleteSuccess = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionGetAllPresentation.pending, (state) => {
        state.presentationLoader = true;
      })
      .addCase(actionGetAllPresentation.fulfilled, (state, action) => {
        console.log(action.payload);
        state.presentations = action?.payload?.data;
        state.presentationLoader = false;
      })

      .addCase(actionGetAllPresentation.rejected, (state) => {
        state.presentationLoader = false;
      })

      .addCase(actionGetSinglePresentation.pending, (state) => {
        state.singlePresentationLoader = true;
      })
      .addCase(actionGetSinglePresentation.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.singlePresentation = action?.payload?.data;
        state.presentations = state.presentations.map((presentation) => presentation.id === data?.id ? data : presentation)
        state.singlePresentationLoader = false;
      })

      .addCase(actionGetSinglePresentation.rejected, (state) => {
        state.singlePresentationLoader = false;
      })

      // Create presentations
      .addCase(actionCreatePresentation.pending, (state) => {
        state.CreatePresentationLoader = true;
      })
      .addCase(actionCreatePresentation.fulfilled, (state) => {
        state.CreatePresentationLoader = false;
      })
      .addCase(actionCreatePresentation.rejected, (state) => {
        state.CreatePresentationLoader = false;
      })

      // Delete presentations
      .addCase(actionDeletePresentation.pending, (state) => {
        state.deletePresentationLoader = true;
      })
      .addCase(actionDeletePresentation.fulfilled, (state, action) => {
        state.presentations = state.presentations.filter(
          (presentation) => presentation.id !== action.payload
        );
        state.deletePresentationLoader = false;
      })
      .addCase(actionDeletePresentation.rejected, (state) => {
        state.deletePresentationLoader = false;
      });
  },
});

export default homeSlice.reducer;
export const { deleteSuccess } = homeSlice.actions;

