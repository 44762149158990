import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../component/config/web.config";
import { deleteSuccess } from "../slices/homeSlice";


//Get Presentation
export const actionGetAllPresentation = createAsyncThunk('actionGetAllPresentation', async () => {
    try {
        const res = await axios.get(`${BASE_URL}/presentation?limit=20&offset=0&getall=YES&order=DESC`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else if (parseInt(status) === 404) {
            return [];
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Get One Presentation
export const actionGetSinglePresentation = createAsyncThunk('actionGetSinglePresentation', async (id) => {
    try {
        const res = await axios.get(`${BASE_URL}/presentation/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            return res.data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})

//Create Presentation
export const actionCreatePresentation = createAsyncThunk('actionCreatePresentation', async (data) => {
    const { request, navigate, setActiveButton, setFileList } = data;
    try {
        const res = await axios.post(`${BASE_URL}/presentation`, request);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            setActiveButton('AllFiles')
            setFileList([])
            return data;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }

})

// Delete Presentation
export const actionDeletePresentation = createAsyncThunk('actionDeletePresentation', async (id, { dispatch }) => {
    try {
        const res = await axios.delete(`${BASE_URL}/presentation/${id}`);
        const { status, message: customMsg } = res.data;
        if (parseInt(status) === 200) {
            message.success(customMsg, 5);
            dispatch(deleteSuccess())
            return id;
        } else {
            message.error(customMsg, 5);
        }
    } catch (error) {
        message.error(error?.message, 5);
    }
})


export const actionPdfDownload = createAsyncThunk('actionDeletePresentation', async (data, { dispatch }) => {
    const fileName = `${data.company_name}-${data.file &&
        data.file.substring(
            data.file.lastIndexOf("/") + 1
        )}`
    try {
        const response = await fetch(
            `${BASE_URL}/${fileName[fileName.length - 1]}`,
            {
                method: "GET",
                responseType: "blob",
            }
        );
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = `${fileName}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        dispatch(actionGetSinglePresentation(data.id));
        URL.revokeObjectURL(blobUrl);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
})