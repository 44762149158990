import React, { useState } from "react";
import logoWithText from "../assets/images/logoWithText.svg";
import smallLogo from "../assets/images/smallLogo.svg";
import celenderIcon from "../assets/images/celenderIcon.svg";
import locationIcon from "../assets/images/locationIcon.svg";
import pptIcon from "../assets/images/pptIcon.svg";
import heart from "../assets/images/heart.svg";
import exportIcon from "../assets/images/exportIcon.svg";
import { Button, Form, Input, Modal } from "antd";

const InnerPage = () => {
  const [submitModal, setSubmitModal] = useState(false);

  //   const handleDownload = async () => {
  //     const fileUrl = "https://api.pinalsand.vytech.co/nophoto.png";
  //     try {
  //       const response = await fetch(fileUrl, {
  //         method: "GET",
  //         responseType: "blob",
  //       });
  //       const blob = await response.blob();
  //       const blobUrl = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = blobUrl;
  //       anchor.download = "nophoto";
  //       document.body.appendChild(anchor);
  //       anchor.click();
  //       document.body.removeChild(anchor);

  //       // Revoke the Blob URL after the download
  //       URL.revokeObjectURL(blobUrl);
  //     } catch (error) {
  //       console.error("Error downloading file:", error);
  //     }
  //   };
  return (
    <div className="bg-[#F6F6F6] h-screen">
      <div className="py-4">
        <img src={logoWithText} alt="logo" className="mx-auto" />
      </div>
      <div className="container mx-auto bg-white rounded-[15px] px-8 py-6 ">
        <div className="flex justify-between items-center">
          <div className="flex space-x-5">
            <div className="border-r pr-5">
              <p className="text-[20px] font-semibold">Exhibition Meetup</p>
              <div className="flex items-center mt-2 space-x-3">
                <div className="bg-[#E6E6E6] h-[50px] w-[50px] rounded-full flex items-center justify-center">
                  <img src={smallLogo} alt="logo" width={40} height={22} />
                </div>
                <div className="w-[200px]">
                  <p className="text-[15px] text-black">
                    <span className="text-[#A4A4A4]">Organized by</span> Indian
                    Laminate Manufacturers Association
                  </p>
                </div>
              </div>
            </div>
            <div className="my-auto space-y-3">
              <div className="flex space-x-3">
                <img src={celenderIcon} alt="icon" />
                <p className="text-[15px] text-black">
                  19th & 20th January, 2024
                </p>
              </div>
              <div className="flex space-x-3">
                <img src={locationIcon} alt="icon" />
                <p className="text-[15px] text-black">Hotel JW Marriott, Goa</p>
              </div>
            </div>
          </div>
          <div className="w-[60px] h-[60px] bg-[#3282FB] rounded-lg text-white flex flex-col justify-center items-center">
            <span className="text-[14px]">JAN</span>
            <span className="text-[19px] font-bold">25</span>
          </div>
        </div>
        <p className="text-[14px] text-justify mt-1 text-[#707070]">
          Welcome to the ILMA Conference 2024, an annual gathering hosted by the
          Indian Laminate Manufacturers Association (ILMA). This distinguished
          event stands as a cornerstone in bringing together manufacturers,
          suppliers, distributors and experts within the vibrant landscape of
          Indian laminate manufacturing industry
        </p>
      </div>
      <div className="bg-white rounded-[15px] px-5 custom-box-shadow w-[60%] mx-auto mt-10">
        <p className="text-[15px] text-black text-center pt-5">
          Your Presentation Are Ready To Download
        </p>
        <div className="border-b-2 border-[#E3E3E3] py-4 flex justify-between">
          <div className="flex space-x-3 items-center ">
            <div className="bg-[#D2EFFB] p-4 rounded-full">
              <img src={pptIcon} alt="icon" />
            </div>
            <div>
              <p className="text-[20px] text-black">Presentation1. PPT</p>
              <p className="text-[11px] text-[#868686]">VYTECH ENTERPRISE</p>
            </div>
          </div>
          <div className="flex space-x-4 items-center">
            <img
              className="cursor-pointer"
              //   onClick={handleDownload}
              onClick={() => setSubmitModal(true)}
              src={exportIcon}
              alt="icon"
            />
          </div>
        </div>
      </div>

      <Modal
        // title="Subscribe to Exhibition"
        centered
        open={submitModal}
        footer={null}
        onCancel={() => setSubmitModal(false)}
      >
        <p className="text-[18px] font-medium text-center">Subscribe to Exhibition</p>
        <Form
          name="basic"
          layout="vertical"
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            className="text-left"
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Enter name" className="2xl:text-[18px] xl:text-[16px] lg:text-[16px] border border-red-500" />
          </Form.Item>
          <Form.Item
            className="text-left"
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: "Please enter your email",
              },

            ]}
          >
            <Input placeholder="Enter Email" className="2xl:text-[18px] xl:text-[16px] lg:text-[16px]" />
          </Form.Item>
          <Form.Item>
            <Button
              // loading={loginStatus?.loginLoader}
              className="w-full pt-[8px] pb-[10px] h-auto rounded-[5px] text-[20px] text-[#fff] border-0 bg-[#3282FB] mt-4"
              htmlType="submit"
            >
              Submit and Download
            </Button>
          </Form.Item>
          <p className="text-[14px] text-[#3282FB]">Skip and Continue to Download</p>
        </Form>
      </Modal>
      <div className="absolute flex bottom-4 left-[45%] text-black text-[15px] items-center">
        Crafted with
        <img src={heart} className="mx-1" /> by Vytech Enterprise
      </div>
    </div>
  );
};

export default InnerPage;
