import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionLogin } from "../../store/services/authService";
import heart from "../../assets/images/heart.svg";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state.auth);
  const token = localStorage.getItem("pinalBackendJwtToken");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const onFinish = (values) => {
    dispatch(actionLogin({ values, navigate }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-b from-[#e3eefe] ">
      <div className="w-[60%] 2xl:w-[30%] xl:w-[40%] lg:lw-[50%] mx-auto">
        <p className="font-semibold text-center text-[30px] 2xl:text-[50px] xl:text-[40px] lg:text-[30px]">
          Login
        </p>
        <div>
          <Form
            className="login-form"
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="text-left"
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: "Please enter your email",
                },
                /* ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                    const isUsername = /^[a-zA-Z0-9_]+$/.test(value);

                    if (value && !isEmail && !isUsername) {
                      return Promise.reject(
                        "Please enter a valid username or email!"
                      );
                    }

                    return Promise.resolve();
                  },
                }), */
              ]}
            >
              <Input
                placeholder="Enter Email"
                className="2xl:text-[18px] xl:text-[16px] lg:text-[16px]"
              />
            </Form.Item>

            <Form.Item
              className="mt-5 text-left"
              name="password"
              label="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                className="2xl:text-[18px] xl:text-[16px] lg:text-[16px]"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loginStatus?.loginLoader}
                className="w-full pt-[8px] pb-[10px] h-auto rounded-[15px] text-[20px] text-[#fff] border-0 bg-[#3282FB] mt-4"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 text-black text-[15px] flex items-center justify-center mb-4">
        <div className="flex items-center">
          Crafted with
          <img src={heart} className="mx-1" alt="heart" /> by Vytech Enterprise
        </div>
      </div>
    </div>
  );
};

export default Login;
