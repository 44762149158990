import { Form, Input, message, Upload, Button, Spin, Empty, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import heart from "../assets/images/heart.svg";
import deleteIcon from "../assets/images/deleteIcon.svg";
import downloadIcon from "../assets/images/downloadIcon.svg";
import exportIcon from "../assets/images/exportIcon.svg";
import linkIcon from "../assets/images/linkIcon.svg";
import pptIcon from "../assets/images/pptIcon.svg";
import pdfIcon from "../assets/images/pdfIcon.svg";
import movIcon from "../assets/images/movIcon.svg";
import xlsIcon from "../assets/images/xlsIcon.svg";
import docIcon from "../assets/images/docIcon.svg";
import { BASE_URL, BASE_URL_UPLOAD } from "./config/web.config";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCreatePresentation,
  actionDeletePresentation,
  actionGetAllPresentation,
  actionGetSinglePresentation,
  actionPdfDownload,
} from "../store/services/homeService";
import { useNavigate } from "react-router-dom";
const { Dragger } = Upload;

const CustomLinkIcon = () => <img src={linkIcon} alt="Custom Icon" />;
const allowedFileTypes = [".docx", ".xlsx", ".pptx", ".ppt", ".pdf", ".mov"];

const Home = () => {
  const [fileList, setFileList] = useState([]);
  const [activeButton, setActiveButton] = useState("Upload");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const presentationsData = useSelector((state) => state.home);
  const { presentations, presentationLoader, deletePresentationLoader } = presentationsData;

  useEffect(() => {
    if (activeButton == "AllFiles") {
      dispatch(actionGetAllPresentation());
    }
  }, [activeButton]);

  const handleSubmit = (val) => {
    const imagesArr = [];

    fileList &&
      fileList.length > 0 &&
      fileList.forEach((d) => {
        console.log({ d });
        if (d.response) {
          imagesArr.push(...d.response.filesData.map(file => file.filename));
        } else {
          imagesArr.push(d.filename);
        }
      });

    const request = {
      ...val,
      files: val.files ? imagesArr : [],
    };
    dispatch(actionCreatePresentation({ request, setActiveButton, setFileList }));
  };


  const customItemRender = (originNode, file, currentFileList) => {
    const handleRemoveFile = () => {
      // Add logic to remove the file from the current file list
      const updatedFileList = currentFileList.filter(
        (item) => item.uid !== file.uid
      );
      setFileList(updatedFileList);
    };

    return (
      <div>
        {file.status === "done" ? (
          <div className="flex justify-between bg-white py-3 px-4 rounded-[15px] mb-3">
            <div className="flex space-x-3 items-center">
              <img src={linkIcon} alt="icon" width={21} />
              <p className="text-[20px] text-black">{file.name}</p>
            </div>
            <div className="flex space-x-4 items-center">
              <img
                src={deleteIcon}
                alt="icon"
                onClick={handleRemoveFile}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        ) : (
          originNode
        )}
      </div>
    );
  };


  const handleDownload = async (data) => {
    dispatch(actionPdfDownload(data));
  };

  const handleDrop = (e) => {
    console.log("Dropped files", e.dataTransfer.files);
  };

  const handleFileChange = (info) => {
    console.log({ info });
    const { status } = info.file;
    console.log({ info });

    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);

  };
  const uploadProps = {
    name: "files",

    multiple: true,
    action: `${BASE_URL_UPLOAD}`,
    beforeUpload: (file) => {
      const fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (!allowedFileTypes.includes(`.${fileExtension.toLowerCase()}`)) {
        message.error(
          `${file.name} is not allowed. Please upload only .docx, .xlsx, .pptx, .mov and .pdf files.`
        );
        return false; // Reject the upload
      }

      return true; // Allow the upload
    },
    onChange: handleFileChange,
    onDrop: handleDrop,
    iconRender: (file, defaultIconRender) => {
      // You can customize the rendering logic here
      return <CustomLinkIcon />;
    },
    itemRender: customItemRender,
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleDeletePresentation = (id) => {
    dispatch(actionDeletePresentation(id));
  };
  console.log({ length: presentations && presentations.length });
  return (
    <>
      <div className="bg-[#F6F6F6] flex flex-col justify-between min-h-screen h-full">
        <div>
          <div className="font-semibold text-center text-[35px] pt-10 ">
            Hello Admin
          </div>
          <div className="flex justify-center space-x-4 my-8  ">
            <div
              className="text-[25px]  cursor-pointer font-medium w-[180px] rounded-[15px] text-center py-[10px] "
              style={{
                background: activeButton === "Upload" ? "#2174EA" : "white",
                color: activeButton === "Upload" ? "white" : "#000",
              }}
              onClick={() => handleButtonClick("Upload")}
            >
              Upload
            </div>
            <div
              className="text-[25px] cursor-pointer font-medium w-[180px] rounded-[15px] text-center py-[10px]"
              style={{
                background: activeButton === "AllFiles" ? "#2174EA" : "white",
                color: activeButton === "AllFiles" ? "white" : "#000",
              }}
              onClick={() => handleButtonClick("AllFiles")}
            >
              All Files
            </div>
          </div>
          <div className="container mx-auto ">
            {activeButton === "Upload" && (
              <Form
                name="basic"
                layout="vertical"
                className="login-form"
                onFinish={handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  className="text-left"
                  label="Company Name"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Company Name"
                    className="w-1/2 2xl:text-[18px] xl:text-[16px] lg:text-[16px] border-0"
                  />
                </Form.Item>
                <Form.Item
                  name="files"
                  rules={[
                    {
                      required: !fileList || fileList.length === 0,
                      message: "Please upload file!",
                    },
                  ]}
                >
                  <Dragger {...uploadProps} accept=".pdf, .xlsx, .xls, .doc, .ppt, .pptx, .mov" fileList={fileList}>
                    <p className="ant-upload-drag-icon">
                      <img src={downloadIcon} alt="downloadIcon" />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Feel free to upload .docx, .xlsx, .pptx, and .pdf
                    </p>
                  </Dragger>
                </Form.Item>
                <Form.Item>
                  <div className="w-1/3 mx-auto">
                    <Button
                      loading={presentationsData?.CreatePresentationLoader}
                      className="w-full mx-auto pt-[8px] pb-[10px] h-auto font-medium rounded-[15px] text-[25px] text-[#fff] border-0 bg-[#3282FB] mt-4"
                      htmlType="submit"
                    >
                      Submit Presentation
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </div>

          {/* Add other components or conditionally render based on the activeButton value */}
          {activeButton === "AllFiles" && (
            <div className="w-[70%] mx-auto custom-height-box">
              <div className="bg-white rounded-[15px] px-5 custom-box-shadow">
                {(
                  presentationLoader ||
                  deletePresentationLoader) && (
                    <Spin className="py-10">
                      <div className="content" />
                    </Spin>
                  )}

                {presentations &&
                  presentations.length > 0 ?
                  presentations.map((data, index) => {
                    const getFileIcon = (fileName) => {
                      const fileExtension = fileName.slice(
                        ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
                      );

                      switch (fileExtension.toLowerCase()) {
                        case "pdf":
                          return pdfIcon;
                        case "ppt":
                        case "pptx":
                          return pptIcon;
                        case "xls":
                        case "xlsx":
                          return xlsIcon;
                        case "doc":
                        case "docx":
                          return docIcon;
                          case "mov":
                          return movIcon;
                        default:
                          return pdfIcon; // You can use a default icon for unknown file types
                      }
                    };
                    return (
                      <div
                        key={index}
                        className={`${index === data.length - 1 ? "" : "border-b-2 border-[#E3E3E3]"} py-4 flex justify-between`}
                      >
                        <div className="flex space-x-3 items-center ">
                          <div className="bg-[#D2EFFB] p-4 rounded-full">
                            {/* <img src={pptIcon} alt="icon" /> */}
                            {data && data.file && (
                              <img src={getFileIcon(data.file)} alt="icon" />
                            )}
                          </div>
                          <div>
                            <p className="text-[20px] text-black">
                              {`${data.company_name}-${data.file &&
                                data.file.substring(
                                  data.file.lastIndexOf("/") + 1
                                )}`}
                            </p>

                            <p className="text-[11px] text-[#868686]">
                              {data && data.company_name}
                            </p>
                            <p className="text-[10px] text-[#545252]">
                              {data && data.download_count} Downloads
                            </p>
                          </div>
                        </div>
                        <div className="flex space-x-4 items-center">
                          <img
                            className="cursor-pointer"
                            onClick={(e) => handleDownload(data)}
                            src={exportIcon}
                            alt="icon"
                          />
                          <Popconfirm
                            okButtonProps={{
                              loading:
                                presentationsData &&
                                presentationsData.deletePresentationLoader,
                            }}
                            placement="top"
                            title="Are you sure you want to delete this File?"
                            onConfirm={() =>
                              handleDeletePresentation(data && data.id)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <img
                              className="cursor-pointer"
                              src={deleteIcon}
                              alt="icon"
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    )
                  }) : <Empty className="not-found py-10" bordered={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </div>


            </div>
          )}
        </div>
        <div className=" my-5 text-black text-[15px] flex items-center justify-center ">
          <div className="flex items-center">
            Crafted with
            <img src={heart} className="mx-1" alt="heart" /> by Vytech
            Enterprise
          </div>
        </div>
      </div >
    </>
  );
};

export default Home;
